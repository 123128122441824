import React, { Component } from 'react'
import { Link } from 'gatsby'

import styles from './SignIn.module.scss'
import Button from 'components/shared/Button'
import Card from 'components/shared/Card'
import {
  Alert,
  FormInput,
  FormContainer,
  FormRow,
  FormCol,
} from 'components/shared/Forms'

const INITIAL_STATE = {
  email: '',
  password: '',
  showError: false,
  error: null,
}

export default class SignInForm extends Component {
  state = {
    ...INITIAL_STATE,
  }

  onSubmit = event => {
    event.preventDefault()

    const { email, password } = this.state
    ;(async () => {
      const error = await this.props.onSubmit(email, password)
      if (error) {
        this.setState({ error, showError: true })
      }
    })()
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { email, password, error, showError } = this.state

    const isInvalid = password === '' || email === ''
    return (
      <FormContainer>
        <Card>
          <form onSubmit={this.onSubmit}>
            <FormRow>
              <FormCol>
                <FormInput label="Email">
                  <input
                    type="email"
                    name="email"
                    autoFocus
                    required
                    maxLength="250"
                    value={email}
                    onChange={this.onChange}
                  />
                </FormInput>
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <FormInput label="Password">
                  <input
                    type="password"
                    name="password"
                    required
                    value={password}
                    onChange={this.onChange}
                  />
                </FormInput>
              </FormCol>
            </FormRow>
            <Alert
              type="error"
              visible={showError}
              message="Incorrect password or email."
            />
            <section className={styles.submit}>
              <Button big full disabled={isInvalid}>
                Sign In
              </Button>
            </section>
            <div className={styles.forgotPassword}>
              <Link to="/users/password_recover/">Forgot password?</Link>
            </div>
          </form>
        </Card>
        <div className={styles.secondaryAction}>
          <div className={styles.prompt}>Don't have an account yet? </div>
          <Link to="/users/sign_up">Create an Account</Link>
        </div>
      </FormContainer>
    )
  }
}
