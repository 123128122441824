import React from 'react'
import * as routes from 'constants/routes'
import { navigate } from 'gatsby'

import SignInForm from 'components/Auth/SignIn'
import Layout from 'layouts/Page'
import PageTitle from 'components/PageTitle'
import SEO from 'components/shared/SEO'
import { AuthService } from 'services'

class LoginPage extends React.Component {
  getRedirectURL = () => {
    let state,
      redirectURL = routes.SEARCH
    if ((state = this.props.location.state)) {
      redirectURL = state.redirectURL || redirectURL
    }
    return redirectURL
  }

  onSubmit = async (email, password) => {
    try {
      await AuthService.doSignInWithEmailAndPassword(email, password)
      navigate(this.getRedirectURL(), {
        replace: true,
        state: { ...(this.props.location.state || {}) },
      })
    } catch (error) {
      return error
    }
  }

  render() {
    return (
      <>
        <PageTitle title="Sign In" />
        <SignInForm onSubmit={this.onSubmit} />
      </>
    )
  }
}

export default ({ ...props }) => (
  <Layout
    {...props}
    navbarProps={{ showSearchBar: false }}
    displayFooter={false}
  >
    <SEO title="Login" />
    <LoginPage />
  </Layout>
)
